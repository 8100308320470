import styled from 'styled-components'

export const SocialButtonAnchor = styled.a<{ paddingTop: number }>`
  background-color: ${props => props.theme.colors.buttonBackground};
  padding: ${props => props.paddingTop || 10}px 15px;
  color: ${props => props.theme.colors.buttonTextColor};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  margin: 5px;

  &:hover {
    background: ${props => props.theme.colors.buttonHoverColor};
  }

  & img {
    margin: 0 10px 0 0;
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 150px;
  }
`
