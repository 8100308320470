import React from 'react'
import Head from 'next/head'
import { HomeContainer } from '../styles/pages/home'
import ButtonsSection from '../components/buttons-section'
import AboutSection from '../components/about-section'
import ProfileImage from '../components/profile-image'
import { AboutWrapper } from '../styles'

const Home: React.FC = () => {
  return (
    <HomeContainer>
      <Head>
        <title>Ricardo Borges</title>
      </Head>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <AboutWrapper>
          <ProfileImage />
          <AboutSection />
        </AboutWrapper>

        <ButtonsSection />
      </div>
    </HomeContainer>
  )
}

export default Home
