import React from 'react'
import useTypewriter from '../../hooks/useTypewriter'
import { Container, Paragraph } from './style'

const TEXT = `
 I'm <b>Ricardo Borges</b>, an enthusiastic software engineer with over a
decade of experience. My journey spans across startups and large
enterprises, where I’ve developed expertise in a wide range of
technologies.
<br />
<br />
With a Bachelor’s degree in Software Engineering, I’m always seeking new
challenges and opportunities to grow. I regularly share insights through
my blog and articles.
<br />
<br />
To learn more about my current projects or to get in touch, feel free to
explore the links below.
`

const AboutSection: React.FC = () => {
  const text1 = useTypewriter(TEXT, 8)

  return (
    <Container>
      <Paragraph dangerouslySetInnerHTML={{ __html: text1 }}></Paragraph>
    </Container>
  )
}

export default AboutSection
