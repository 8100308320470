import React, { useEffect, useState } from 'react'
import SocialButton from '../social-button/social-button'
import { Theme, useTheme } from '../../providers/theme'
import { Container } from './style'

const ButtonsSection: React.FC = () => {
  const { theme } = useTheme()
  const [githubImageURI, setGithubImageURI] = useState('/github-icon-light.svg')
  const [linkedinImageURI, setLinkedinImageURI] = useState(
    '/linkedin-icon-light.svg'
  )
  const [mailImageURI, setMailImageURI] = useState('/mail-icon-light.svg')
  const [npmImageURI, setNpmImageURI] = useState('/npm-icon-light.svg')
  const [devImageImageURI, setDevImageURI] = useState('/dev-icon-light.svg')

  useEffect(() => {
    const newGithubImageURI =
      theme === Theme.DARK ? '/github-icon.svg' : '/github-icon-light.svg'
    const newLinkedinImageURI =
      theme === Theme.DARK
        ? '/linkedin-icon-2.svg'
        : '/linkedin-icon-light-2.svg'
    const newMailImageURI =
      theme === Theme.DARK ? '/mail-icon.svg' : '/mail-icon-light.svg'
    const newNpmImageURI =
      theme === Theme.DARK ? '/npm-icon.svg' : '/npm-icon-light.svg'
    const newDevImageURI =
      theme === Theme.DARK ? '/dev-icon.svg' : '/dev-icon.svg'

    setGithubImageURI(newGithubImageURI)
    setLinkedinImageURI(newLinkedinImageURI)
    setMailImageURI(newMailImageURI)
    setNpmImageURI(newNpmImageURI)
    setDevImageURI(newDevImageURI)
  }, [theme])

  return (
    <Container>
      <SocialButton
        src={mailImageURI}
        alt="gmail icon"
        url="mailto:ricardo93borge@gmail.com"
        text="Email"
      />
      <SocialButton
        src={linkedinImageURI}
        alt="linkedin icon"
        url="https://www.linkedin.com/in/ricardo-borges-silva/"
        text="LinkedIn"
      />
      <SocialButton
        src={githubImageURI}
        alt="github icon"
        url="https://github.com/ricardo93borges"
        text="Github"
      />
      <SocialButton
        src={npmImageURI}
        alt="npm icon"
        url="https://www.npmjs.com/~ricardo93borges"
        text="NPM"
        height={32}
        width={32}
        paddingTop={6}
      />
      <SocialButton
        src={devImageImageURI}
        alt="dev.to icon"
        url="https://dev.to/ricardo_borges"
        text="Dev.to"
        height={32}
        width={32}
        paddingTop={6}
      />
    </Container>
  )
}

export default ButtonsSection
