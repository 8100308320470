import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 275px;

  @media (max-width: 768px) {
    margin: 20px 0;
    height: 400px;
  }
`

export const Paragraph = styled.p`
  font-size: 1.3em;
  width: 545px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
