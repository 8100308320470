import styled from 'styled-components'

export const HomeContainer = styled.main`
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`
