import React from 'react'
import Image from 'next/image'
import { SocialButtonAnchor } from './style'

type SocialButtonProps = {
  url: string
  src: string
  text: string
  alt: string
  width?: number
  height?: number
  paddingTop?: number
}

const SocialButton: React.FC<SocialButtonProps> = ({
  url,
  src,
  text,
  alt,
  width = 24,
  height = 24,
  paddingTop
}) => {
  return (
    <SocialButtonAnchor
      paddingTop={paddingTop}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src={src} width={width} height={height} alt={alt} />
      <b>{text}</b>
    </SocialButtonAnchor>
  )
}

export default SocialButton
