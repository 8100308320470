import React from 'react'
import Image from 'next/image'
import { ProfileImageContainer } from './style'

const ProfileImage: React.FC = () => {
  return (
    <ProfileImageContainer>
      <Image
        className="profileImage fade-in-image"
        src="/me-2.jpeg"
        alt="Ricardo Borges"
        width={175}
        height={175}
      />
    </ProfileImageContainer>
  )
}

export default ProfileImage
