import styled from 'styled-components'

export const ProfileImageContainer = styled.div`
  margin-right: 20px;
  display: inline-flex;
  align-items: center;

  img {
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 0;
  }
`
